<div class="icon"
     [ngClass]="[icon]">
  <abm-icon src="assets/icons/insights/icon-{{icon}}.svg"
            [width]="18"
            [height]="18"></abm-icon>
</div>

<div class="insight-content">
  <p class="text"
     (click)="goToMatrix(insight)">
    {{ insight.message }}
  </p>
  <p class="insight-date">
    {{ insight.created_at | date: 'short' }}
  </p>
</div>

@if (null | insightsPermissionFull) {
<abm-checkbox [ngModel]="insight.is_read"
              (checkboxChange)="insightChange.emit(insight)" />
}
