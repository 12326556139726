import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { InsightCounterResponse, InsightItem } from '@type/insight.type';
import { joinUrls } from '@utils/urls';
import { Subject, catchError, of } from 'rxjs';

const MARK = 'mark';
const MARK_ALL = 'mark_all';

@Injectable({
  providedIn: 'root',
})
export class InsightService {
  private http = inject(HttpClient);

  updateInsightTrigger = new Subject<boolean>();

  getInsightList(categoryId: number) {
    const { categories, insights, index } = environment.urls;
    return this.http.post<InsightItem[]>(joinUrls(categories, String(categoryId), insights, index), {
      sorting: 'created_at desc',
    });
  }

  getUnreadCounter(categoryId: number) {
    const { categories, insights, count } = environment.urls;
    return this.http
      .get<InsightCounterResponse>(joinUrls(categories, String(categoryId), insights, count))
      .pipe(catchError(() => of({ not_read_count: 0 })));
  }

  changeInsightReadState(categoryId: number, insightId: number) {
    const { categories, insights } = environment.urls;
    return this.http.put(joinUrls(categories, String(categoryId), insights, String(insightId), MARK), {});
  }

  changeAllInsightReadState(categoryId: number) {
    const { categories, insights } = environment.urls;
    return this.http.put(joinUrls(categories, String(categoryId), insights, MARK_ALL), {});
  }
}
